import { Box } from '@mui/material'
import { ContactInfoDialog } from '../../components/ContactInfoDialog'
import { AccountInfoDialog } from '../../components/AccountInfoDialog'
import { HomeRouteLink } from '../../components/HomeRouteLink'
import {
  AccountCircleRounded,
  ContactPageRounded,
  GroupAddRounded,
  KeyRounded,
  LoginRounded,
  OndemandVideoRounded,
  NewReleases,
} from '@mui/icons-material'
import { HomeDialogButton } from '../../components/HomeDialogButton'
import { LogOutButton } from '../../components/LogOutButton'
import { colors } from '../../colors'
import carrotIcon from '../../icons/carrotIcon.png'
import { useEffect, useState } from 'react'
import { Header } from './Header'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Paper } from '@mui/material'

export const Home = ({
  sessionId,
  accountId,
  openCopySnackbar,
  userViews,
  isGettingUserViews,
  isLoggedIn,
  handleLogOut,
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [showContactInfo, setShowContactInfo] = useState(false)
  const [showAccountInfo, setShowAccountInfo] = useState(false)

  useEffect(() => {
    if (userViews?.finished_onboarding === false) {
      navigate({ pathname: '/onboarding', search: searchParams.toString() })
    }
  }, [userViews])

  return (
    <div class="noselect" style={{ paddingTop: '70px' }}>
      <Header />
      
      {/* New Version Banner */}
      <Paper
        elevation={3}
        sx={{
          background: 'linear-gradient(145deg, #4b50e4 0%, #5F65F4 45%, #5357dd 100%)',
          padding: '12px 16px',
          margin: '0 auto',
          marginTop: '10px',
          marginBottom: '20px',
          maxWidth: '90%',
          borderRadius: '24px',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          border: 'none',
          position: 'relative',
          zIndex: 100,
          boxShadow: '0 8px 20px rgba(95, 101, 244, 0.3), 0 0 0 2px rgba(95, 101, 244, 0.3)',
          overflow: 'hidden',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '24px',
            padding: '2px',
            background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(150, 155, 255, 0.5) 100%)',
            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            pointerEvents: 'none',
            opacity: 0.8
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            background: 'linear-gradient(135deg, rgba(95, 101, 244, 0.1) 0%, rgba(95, 101, 244, 0.1) 100%)',
            borderRadius: '26px',
            zIndex: -1,
            filter: 'blur(8px)',
            pointerEvents: 'none'
          }
        }}
      >
        {/* Metallic shine effects */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '35%',
            background: 'linear-gradient(180deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0) 100%)',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            pointerEvents: 'none',
            zIndex: 1
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '-200%',
            width: '300%',
            height: '40px',
            background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0) 100%)',
            transform: 'rotate(-45deg)',
            pointerEvents: 'none',
            opacity: 0.9,
            zIndex: 1,
            animation: 'shine 4s infinite linear',
            '@keyframes shine': {
              '0%': {
                transform: 'translateX(-30%) rotate(-45deg)'
              },
              '100%': {
                transform: 'translateX(100%) rotate(-45deg)'
              }
            }
          }}
        />
        
        {/* Holographic effect */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100px',
            height: '100px',
            background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%)',
            opacity: 0.4,
            pointerEvents: 'none',
            zIndex: 1,
          }}
        />
        
        {/* Light sparkles */}
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            right: '10%',
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            background: 'white',
            boxShadow: '0 0 3px 1px rgba(255,255,255,0.8)',
            pointerEvents: 'none',
            zIndex: 1,
            opacity: 0.7,
            animation: 'sparkle1 7s infinite'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '70%',
            left: '30%',
            width: '3px',
            height: '3px',
            borderRadius: '50%',
            background: 'white',
            boxShadow: '0 0 3px 1px rgba(255,255,255,0.8)',
            pointerEvents: 'none',
            zIndex: 1,
            opacity: 0.7,
            animation: 'sparkle2 5s infinite'
          }}
        />
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'flex-start', 
          gap: '10px', 
          position: 'relative',
          zIndex: 5
        }}>
          {/* Sale Badge */}
          <Box 
            sx={{
              position: 'absolute',
              top: { xs: '-12px', sm: '-20px' },
              left: { xs: '10px', sm: '-15px' },
              backgroundColor: 'white',
              color: '#5F65F4',
              fontWeight: 'bold',
              fontSize: { xs: '10px', sm: '12px' },
              padding: '4px 10px',
              borderRadius: '20px',
              transform: 'rotate(-10deg)',
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              zIndex: 10,
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(135deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 60%)',
                borderRadius: '20px',
                zIndex: 1
              }
            }}
          >
            20% OFF
          </Box>
          <NewReleases sx={{ color: 'white' }} />
          <Box sx={{ fontWeight: 'bold', color: 'white', paddingTop: { xs: '12px', sm: '0' } }}>
            Try our brand new version with improved speed and reliability!
            <Box 
              component="span" 
              sx={{ 
                display: 'block', 
                fontSize: '0.85em', 
                color: 'rgba(255, 255, 255, 0.85)', 
                fontWeight: '500',
                mt: 0.5
              }}
            >
              Special offer: 20% discount on weekly & monthly plans
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            window.open('https://batchfinder.com/landing', '_blank');
          }}
          sx={{ 
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            minWidth: '120px',
            textTransform: 'uppercase',
            background: 'white',
            color: '#5F65F4',
            borderRadius: '24px',
            padding: '10px 20px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 1)',
            position: 'relative',
            overflow: 'hidden',
            transition: 'all 0.3s ease',
            marginTop: { xs: '10px', sm: 0 },
            zIndex: 5,
            '&:hover': {
              background: 'white',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 1)',
              transform: 'translateY(-2px)'
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '-100%',
              width: '100%',
              height: '100%',
              background: 'linear-gradient(90deg, transparent, rgba(95, 101, 244, 0.2), transparent)',
              transition: 'all 0.7s ease',
            },
            '&:hover:before': {
              left: '100%'
            }
          }}
        >
          Check it out
        </Button>
      </Paper>
      
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '0',
        }}
      >
        <ContactInfoDialog
          visible={showContactInfo}
          hideContactInfo={() => setShowContactInfo(false)}
        />
        <AccountInfoDialog
          visible={showAccountInfo}
          hideAccountInfo={() => setShowAccountInfo(false)}
          accountId={accountId}
          openCopySnackbar={openCopySnackbar}
        />
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!isLoggedIn && (
            <HomeRouteLink
              label="Log In"
              subLabel="Or Create Account"
              to="/login"
              icon={
                <LoginRounded
                  color={colors.black}
                  sx={{
                    marginRight: 'auto',
                    fontSize: 28,
                  }}
                />
              }
              glow={true}
            />
          )}
          {isLoggedIn && (
            <HomeDialogButton
              onClick={() => setShowAccountInfo(true)}
              label="Account"
              icon={
                <AccountCircleRounded
                  color={colors.black}
                  sx={{ marginRight: 'auto', fontSize: 28 }}
                />
              }
            />
          )}
          {/* {((!isGettingUserViews && !userViews) ||
            (userViews && !userViews.setup_guide_video)) && (
            <HomeRouteLink
              label="Setup Guide & Proof"
              to="/batchfinder/guide/video"
              icon={
                <OndemandVideoRounded
                  color={colors.black}
                  sx={{ marginRight: 'auto', fontSize: 28 }}
                />
              }
              glow={true}
            />
          )} */}
          <HomeRouteLink
            label="BatchFinder"
            to="/batchfinder"
            icon={
              <img
                src={carrotIcon}
                alt="carrot"
                style={{
                  height: '30px',
                  marginRight: 'auto',
                }}
              />
            }
          />
          {!!sessionId && (
            <HomeRouteLink
              label="License Access"
              to="/license"
              icon={
                <KeyRounded
                  color={colors.black}
                  sx={{
                    marginRight: 'auto',
                    fontSize: 28,
                  }}
                />
              }
            />
          )}
          {!!sessionId && (
            <HomeRouteLink
              label="Earn Free Licenses"
              subLabel="Referral System"
              to="/referral"
              icon={
                <GroupAddRounded
                  color={colors.black}
                  sx={{
                    marginRight: 'auto',
                    fontSize: 28,
                  }}
                />
              }
            />
          )}
          <HomeDialogButton
            onClick={() => setShowContactInfo(true)}
            label="Contact"
            icon={
              <ContactPageRounded sx={{ marginRight: 'auto', fontSize: 28 }} />
            }
          />
          <LogOutButton handleLogOut={handleLogOut} sessionId={sessionId} />
        </div>
      </div>
    </div>
  )
}
